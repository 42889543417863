export const INITIAL_ASYNC_STATUS = {
  loading: false,
  success: false,
  rejected: false,
};

export enum CONFIRM_MODAL_TYPE_INFO {
  OVERDUE_CYCLE = 'overdueCycle',
  ASSESSMENT_NON_ENGAGED_CYCLE = 'assessmentNonEngagedCycle',
  EXPECTATION_NON_ENGAGED_CYCLE = 'expectationNonEngagedCycle',
  CANCEL_EDIT_CYCLE = 'cancelEditCycle',
  CANCEL_CYCLE = 'cancelCycle',
  DONE_CYCLE = 'doneAddCycle',
  DELETE_EXPECTATION = 'deleteExpectation',
  UNDO_CHANGE = 'undoChange',
  PP_SUBMIT_ASSESSMENT = 'pp submit assessment',
  AP_SUBMIT_ASSESSMENT = 'ap submit assessment',
  TBP_BULK_UPLOAD_INDICATORS = 'tbp bulk upload indicators',
  PP_DONE_COMMUNICATION = 'pp done calibration communication',
}

export enum CYCLE_OPERATION_STAGE {
  ASSESSMENT_STAGE = 'ASSESSMENT_STAGE',
  CALIBRATION_STAGE = 'CALIBRATION_STAGE',
  COMMUNICATION_STAGE = 'COMMUNICATION_STAGE',
  GRIEVANCE_STAGE = 'GRIEVANCE_STAGE',
  DEFAULT = 'DEFAULT',
}

export enum CYCLE_CALIBRATE_STAGE {
  H1_CYCLE_CALIBRATE_STAGE = 'H1_CYCLE_CALIBRATE_STAGE',
  H2_CYCLE_CALIBRATE_STAGE = 'H2_CYCLE_CALIBRATE_STAGE',
  DEFAULT = 'DEFAULT',
}

//TODO
export enum CYCLE_AUTO_OPEN_DATE {
  H1 = 'January 1st',
  H2 = 'July 1st',
}

export enum OVERDUE_DEADLINE {
  H1 = 'June 20th',
  H2 = 'December 20th',
}

export enum ASSESSMENT_NON_ENGAGED_DEADLINE {
  H1 = 'August 17th',
  H2 = 'February 17th',
}

export enum REQUEST_TO_EDIT_DEADLINE {
  H1 = 'August 17th',
  H2 = 'February 17th',
}

export enum PP_ADD_ASSESSMENT_DATE {
  H1 = 'May 15th',
  H2 = 'November 15th',
}

export const CALIBRATION_COMMUNICATION_DURATION = {
  H1: {
    startDate: 'July 27th',
    endDate: 'August 10th',
  },
  H2: {
    startDate: 'January 27th',
    endDate: 'February 10th',
  },
};

export enum AUTO_PUBLISH_CALIBRATING_RESULT_DATE {
  H1 = 'August 11th',
  H2 = 'February 11th',
}

export const TBP_BULK_UPLOAD_INDICATOR_DURATION = {
  H1: {
    startDate: 'June 21st',
    endDate: 'August 17th',
  },
  H2: {
    startDate: 'December 21st',
    endDate: 'February 17th',
  },
};

export enum MONTH_NUMBER {
  January = 0,
  February = 1,
  June = 5,
  August = 7,
}
